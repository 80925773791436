import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    saveFormConfig,
    getCustomerClass
} from '../actions/paynowActions';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest,
    fetchMultipleLatestBill,
    saveOrderData,
    savePaymentData,
    validateVisaPayment
} from '../actions/dashboardActions';
import { 
    showMessage
} from '../actions/popupMessageActions';
import { Form, Col, InputGroup, FormControl, Modal } from 'react-bootstrap';
import $ from 'jquery';
import * as moment from 'moment';
import ResultMessage from './resultMessage';
import DatePicker from "react-datepicker";
import NumberFormat from 'react-number-format';

class PaynowStep3 extends Component {
    constructor(){
        super()
        this.state = {
            showHint: false,
            showResult: true,
            resultMsgTitle: 'test',
            resultMsgBody: 'test',
            validated: false,
            amountToBePaid: '',
            accountSummary: "",
            subtotal: "",
            expDateForm: "",
            showErrMsg: false,
            isPaymentProcessing: false,
            username: "",
            userFullName: "",
            cardDetails: {
                cardHolderName: "",
                cardNumber: "",
                expDate: new Date(),
                validExpDate: "",
                cvv: "",
                billingZipCode: "",
                confirmationEmail: ""
            },
            isVisaChecked: "",
            isMasterCardChecked: "",
            mode: '',
            usedCC: 'visa'
        }
    }
    maskString = (value) => {
        let str = value;
        let count = 0;
        let new_str = "";
        try{
            for(let i = 0; i < str.length; i++){
                if(count > 1){
                    if(str.charAt(i) != " "){
                        new_str = new_str + '*'
                        count = count + 1
                    }
                    else{
                        new_str = new_str + " "
                        count = 0;
                    }
                    
                }
                else if(count < 2){
                    if(str.charAt(i) != " "){
                        new_str = new_str + str.charAt(i)
                        count = count + 1
                    }
                    else{
                        new_str = new_str + " "
                        count = 0;
                    }
                }
                
            }
            return new_str;
        }
        catch(error){
            return "";
        }
    }
    componentWillMount(){
        this.props.saveFormConfig({btnSubmitValue: "Continue"})
        
        var _ = this;
        $("#submit").click(function() {
            _.handleSubmit();
        });

        
        this.setState({
            ...this.state,
            accountSummary: this.props.dashboard.orderData.accountSummary,
            subtotal: this.props.dashboard.orderData.subTotal,
            username: this.props.paynow.validateZipCodeRes.result.details.Name,
            userFullName: this.props.paynow.validateZipCodeRes.result.details.Name,
            cardDetails: {
                confirmationEmail: this.props.paynow.validateZipCodeRes.result.details.EmailID
            }
        }, ()=>{
            console.log("this.state 3", this.state)
        });
    }
    
    saveOrderData = () => {
        const postData = {
            subTotal: this.state.subtotal,
            accountSummary: this.state.accountSummary,
            isHasInvalid: false
        }
        this.props.saveOrderData(postData)
    }
    
    updateSubTotal = () => {
        let subtotal = 0
        const arrAccountSummary = this.state.accountSummary
        for(let count = 0; count < arrAccountSummary.length; count++){
            if(arrAccountSummary[count].checked){
                subtotal = subtotal + parseFloat(arrAccountSummary[count].amountToBePaid)
            }
        }
        this.setState({
            subtotal: subtotal
        },() => {
            this.saveOrderData()
        })
    }
    formatAmount = (value) => {
        value = value.replace('$ ',''); //removes '$ '
        value = value.replace(/\,/g,'') //removes all ','
        return value = parseFloat(Math.round(value * 100) / 100).toFixed(2)
    } 
    amountToBePaidOnChange = (e) => {
        let id = e.target.id, value = e.target.value
        value = this.formatAmount(value);
        const arrAccountSummary = this.state.accountSummary
        let selectedIndex = ""
        for(let count = 0; count < arrAccountSummary.length; count++){
            if(arrAccountSummary[count].accID === id){
                selectedIndex = count
            }
        }
        arrAccountSummary[selectedIndex].amountToBePaid = (value === "") ? 0.00 : value
        arrAccountSummary[selectedIndex].checked = true
        this.setState({
            ...this.state,
            accountSummary: arrAccountSummary
        },()=>{this.updateSubTotal()})
    }
    
    async handleSubmit(){
        this.setState({
            validated: true,
            isPaymentProcessing: true
        }, () => {
            const _ = this.state
            if(_.subtotal != ""){
                console.log("this.state.accountSummary", this.state.accountSummary)
                this.props.saveFormConfig({btnSubmitValue: "Please wait..."})
            }
            else{
                this.props.saveFormConfig({btnSubmitValue: "Continue"})
            }
        })
    }
    
    newTabOpen = () => {
        window.location.replace( "/payment-result")
    }

    render() { 
        const { validated } = this.state;
        let fullName  = this.props.paynow.validateZipCodeRes.result.details.Name;
        const finalFullName = this.maskString(fullName);

        const {details, source} = this.props.paynow.validateZipCodeRes.result;
        let address1 = '';
        address1 = source != "ACOV" ? (details.Address ?? (details.PremiseInfo ?? "N/A")) : details.personAddressOverride.address1 + ', ' + details.personAddressOverride.city + ', '+ details.personAddressOverride.state + ', '+ details.personAddressOverride.postal + ', '+ details.personAddressOverride.country;
        const finalAdd1 = this.maskString(address1);

        return (  
            <React.Fragment>
                <Form
                    autocomplete="off"
                    noValidate
                    validated = {validated}
                    onSubmit = {e => this.handleSubmit(e)}
                >
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label className="font-bold">Amount to be paid</Form.Label>
                            <NumberFormat 
                                id={this.props.paynow.step1Data.accountId}
                                className="form-control"
                                name="amountToBePaid"
                                value={parseFloat(this.props.dashboard.orderData.accountSummary[0].amountToBePaid)}
                                // value={parseFloat(item.amountToBePaid)}
                                thousandSeparator={true} 
                                prefix={'$ '} 
                                onChange={this.amountToBePaidOnChange.bind(this)}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}   
                                required
                                isAllowed={(values) => {
                                    const { formattedValue, floatValue } = values;
                                    return (floatValue<=99999.99);
                                }}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter Amount.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label className="font-bold">Customer Name</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="cname"
                                value={finalFullName}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Customer Name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label className="font-bold">Address</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="address"
                                value={finalAdd1}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, {
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest,
    fetchMultipleLatestBill,
    saveOrderData,
    savePaymentData,
    validateVisaPayment,
    showMessage,
    saveFormConfig,
    getCustomerClass
})(PaynowStep3);